<template>
  <div>
    <v-tabs-items v-model="tab" class="pb-12">
      <v-tab-item v-for="(tab, tab_i) in tabData" :key="`tab_${tab_i}`">
        <v-card flat>
          <v-list two-line>
            <template v-for="(item, item_i) in tab.data">
              <v-list-item :key="`t_${tab_i}_r_${item_i}`">
                <v-list-item-avatar v-if="item.image">
                  <v-img :src="item.image"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-if="item.name" v-html="item.name"></v-list-item-title>
                  <v-list-item-title v-if="item.track" v-html="item.track"></v-list-item-title>
                  <v-list-item-subtitle v-if="item.artists" v-html="item.artists"></v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.createdAt" v-html="item.createdAt"></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-icon v-if="tab.rank == true">
                  <b>{{ item_i + 1 }}</b>
                </v-list-item-icon>
              </v-list-item>

              <v-divider :key="`dt_${tab_i}_r_${item_i}`" :inset="item.image"/>
            </template>

            <NoInfo v-if="tab.data == 0" />
          </v-list>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-footer color="primary" fixed padless>
      <v-tabs fixed-tabs background-color="primary" dark
        v-model="tab"
      > 
        <v-tab v-for="(tab, tab_i) in tabData" :key="tab_i">
          {{ $t(tab.title) }}
        </v-tab>
      </v-tabs>
    </v-footer>
  </div>
</template>

<script>
import NoInfo from './NoInfo'

export default {
  components: {
    NoInfo
  },
  data() {
    return {
      tab: null,
    }
  },
  props: {
    tabData: Array
  }
}
</script>